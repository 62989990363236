import * as S from './WorkTogether.styled'
import * as G from '../Global.styled'
import SectionTitle from '../SectionTitle'
import { workTogetherHomeData } from '@Components/Website/Home/constants'

const WorkTogether = () => {
  const { title, description } = workTogetherHomeData
  return (
    <S.WorkTogether>
      <G.Container>
        <S.WorkTogetherInner>
          <SectionTitle title={title} color='secondary2' />
          <S.Description>{description}</S.Description>
        </S.WorkTogetherInner>
      </G.Container>
    </S.WorkTogether>
  )
}

export default WorkTogether
