import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const TextareaWrap = styled.div`
  ${tw`relative`}
`

export const Textarea = styled.textarea<{
  filled?: boolean
  error?: boolean
  success?: boolean
}>`
  min-height: 120px;
  ${tw`text-base bg-white w-full border-input rounded-base p-4 border focus:outline-none`}

  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #c1c7d0;
    border-radius: 6px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #c1c7d0;
  }

  &:focus {
    ${tw`border-primary shadow-primary-short`}
  }

  ${({ filled }) => filled && css``}

  ${({ error }) =>
    error &&
    css`
      ${tw`border-danger! text-danger! shadow-danger-short!`}

      &:focus {
        ${tw`border-danger text-danger shadow-danger-short`}
      }
    `}

  ${({ success }) =>
    success &&
    css`
      ${tw`border-success shadow-success-short`}

      &:focus {
        ${tw`border-success shadow-success-short`}
      }
    `}
`

export const Count = styled.div`
  transform: translateY(-50%);
  ${tw`absolute right-5 top-7`}
`
