import styled from 'styled-components'
import tw from 'twin.macro'

const handleBgColor = (color: string) => {
  switch (color) {
    case 'primary1':
      return tw`bg-primary1`
    case 'secondary2':
      return tw`bg-secondary2`
    default:
      return tw`bg-primary1`
  }
}

export const ViewMoreInner = styled.div`
  ${tw`relative h-[200%] w-full duration-500`}
`

export const TextMain = styled.p`
  ${tw`font-bold 2xl:text-[42px] xl:text-[36px] text-[22px] text-white 2xl:leading-[47px] xl:leading-[46px] leading-[28px]  h-[50%] flex flex-col items-center justify-center absolute top-0`}
`

export const TextMore = styled.p`
  ${tw`font-bold xl:text-[24px] lg:text-[20px] sm:text-xl text-lg text-white xl:leading-[42px] lg:leading-[30px] h-[50%] flex flex-col items-center justify-center absolute top-1/2`}
`

export const ViewMoreButton = styled.div`
  ${tw`absolute sm:bottom-[40px] bottom-[20px] sm:right-[52px] right-[30px] flex items-center`}
`

export const ViewMoreButtonTitle = styled.p`
  ${tw`font-bold 2xl:text-[28px] xl:text-[24px] text-base 2xl:leading-[36px] xl:leading-[31px] leading-[20px] text-white mr-2 inline-block duration-500 cursor-pointer`}
`

export const ArrowSymbol = styled.span`
  ${tw`relative sm:w-3 w-2 inline-block duration-500 cursor-pointer`}
`

export const ViewMore = styled.div<{ viewMore: boolean; bgColor?: string }>`
  ${[
    tw`w-full h-full md:mb-0 mb-[20px] relative bg-primary1 rounded-[5px] sm:px-[50px] px-[30px] overflow-hidden`,
    ({ bgColor }) => bgColor && handleBgColor(bgColor),
  ]}

  ${ViewMoreInner} {
    ${({ viewMore }) => viewMore && tw`translate-y-[-50%]`}
  }

  ${ArrowSymbol} {
    ${({ viewMore }) => viewMore && tw`-rotate-180`}
  }

  ${ViewMoreButtonTitle} {
    ${({ viewMore }) => viewMore && tw`translate-x-[500px]`}
  }
`
