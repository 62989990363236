import * as S from './Quote.styled'
import * as G from '../../Global/Global.styled'

const Quote = () => {
  return (
    <S.Quote>
      <G.Container>
        <S.QuoteInner>
          Gain your competitive edge: Target markets, build client confidence,
          save time, negotiate effectively, and mitigate risks -{' '}
          <S.HighlightText>
            your way.
            <S.UnderlineWrap>
              <img src='/static/imgs/home/underline.png' alt='Banto' />
            </S.UnderlineWrap>
          </S.HighlightText>
        </S.QuoteInner>
      </G.Container>
    </S.Quote>
  )
}

export default Quote
