import { DEVICES } from '@Constants/global'
import styled from 'styled-components'
import tw from 'twin.macro'

export const IntegrationProviders = styled.div`
  ${tw`lg:py-[40px] py-[20px]`}
`

export const IntegrationProvidersNote = styled.div`
  ${tw`font-bold 2xl:text-[28px] xl:text-[24px] text-base text-center 2xl:leading-[32px] xl:leading-[28px] leading-[28px] mb-0 text-secondary2`}
`

export const SliderWrap = styled.div`
  ${tw`mb-[115px] pt-[35px] max-w-[930px] mx-auto`}
  ${tw`mobile:(max-w-[70%])`}

  .slick-dots {
    ${tw`bottom-[-55px]`}
    li {
      ${tw`lg:w-[20px] lg:h-[20px] mx-[7px] w-[8px] h-[8px]`}
      &.slick-active {
        button:before {
          ${tw`bg-primary`}
        }
      }
      button {
        ${tw`lg:w-[20px] lg:h-[20px] w-[8px] h-[8px]`}
      }

      button:before {
        content: '';
        ${tw`lg:w-[11px] lg:h-[11px] w-[8px] h-[8px] bg-[#D9D9D9] rounded-full opacity-100`}
      }
    }
  }

  .slick-next,
  .slick-prev {
    ${tw`2xl:w-[50px] 2xl:h-[56px] md:h-[40px] md:w-[40px] w-[30px] h-[30px]`}

    &::before {
      content: '';
    }

    @media ${DEVICES.tablet} {
      background-size: 40px 40px;
    }

    @media ${DEVICES.mobile} {
      background-size: 30px 30px;
    }
  }

  .slick-prev {
    background-image: url('/static/imgs/home/chevron-left.png');
    background-size: 100%;
    ${tw`2xl:left-[ -15%] lg:left-[-5%] md:left-[-16px] left-0`}
    ${tw`mobile:(left-[-20%])`}
  }

  .slick-next {
    background-image: url('/static/imgs/home/chevron-right.png');
    background-size: 100%;
    ${tw`2xl:right-[ -15%] lg:right-[-5%] md:right-[-16px] right-0`}
    ${tw`mobile:(right-[-20%])`}
  }
`

export const ProviderItems = styled.div`
 
`

export const ProviderItem = styled.div`
  ${tw`xl:p-[35px] lg:p-[20px] text-center p-[15px]`}
`

export const ProviderItemImg = styled.img`
  ${tw`xl:max-h-[48px] lg:max-h-[28px] mx-auto max-h-[20px]`}
`
