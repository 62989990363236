import styled from 'styled-components'
import tw from 'twin.macro'

export const GetInTouch = styled.div`
  ${tw`bg-[rgba(35, 112, 131, 0.16)] lg:py-[80px] py-[40px]`}
`

export const FormWrap = styled.div`
  ${tw`flex items-center md:flex-row flex-col`}
`

export const FormInfo = styled.div`
  ${tw`max-w-[290px] md:mr-[80px]`}
  ${tw`mobile:(mb-[32px])`}

  h2 {
    ${tw`hidden text-center mobile:(block) mb-[20px]`}
  }

  p {
    ${tw`text-2xl text-center text-blueBayoux font-bold leading-[160%]`}
    ${tw`mobile:(text-base leading-[25px])`}
  }

  img {
    ${tw`w-[212px] mb-[20px] mx-auto`}
    ${tw`w-[125px]`}
  }
`

export const Form = styled.form`
  ${tw`flex-1`}

  h2 {
    ${tw`mobile:(hidden)`}
  }

  > p {
    ${tw`mb-[20px]`}
  }

  > div:first-child {
    ${tw`mb-[20px]`}
  }

  label {
    ${tw`text-secondary2 font-semibold text-sm mb-2`}
  }

  input {
    ${tw`h-[46px] text-sm border-[rgba(35, 112, 131, 0.50)]`}
  }

  textarea {
    ${tw`min-h-[105px] border-[rgba(35, 112, 131, 0.50)]`}
  }

  button {
    ${tw`font-bold`}
    ${tw`mobile:(block w-full)`}
  }
`

export const FormAction = styled.div`
  ${tw`text-right`}
`
