import * as S from './ViewMore.styled'
import { useState } from 'react'

interface ViewMoreProps {
  textMain: string
  textMore: string
  bgColor?: string
  viewMoreText?: string
  className?: string
}
const ViewMore = ({
  textMore,
  textMain,
  bgColor,
  viewMoreText,
  className,
}: ViewMoreProps) => {
  const [viewMore, setViewMore] = useState(false)
  return (
    <S.ViewMore viewMore={viewMore} bgColor={bgColor} className={className}>
      <S.ViewMoreInner>
        <S.TextMain>{textMain}</S.TextMain>
        <S.TextMore>{textMore}</S.TextMore>
      </S.ViewMoreInner>

      <S.ViewMoreButton>
        <S.ViewMoreButtonTitle onClick={() => setViewMore(!viewMore)}>
          {viewMoreText ? viewMoreText : 'Find out more'}
        </S.ViewMoreButtonTitle>
        <S.ArrowSymbol onClick={() => setViewMore(!viewMore)}>
          <img src='/static/imgs/home/Arrow.svg' alt='Banto Arrow' />
        </S.ArrowSymbol>
      </S.ViewMoreButton>
    </S.ViewMore>
  )
}

export default ViewMore
