import styled from 'styled-components'
import tw from 'twin.macro'

export const SectionTitle = styled.h2<{ textAlign?: string; color?: string }>`
  ${[
    tw`2xl:text-[48px] lg:text-[40px] text-[22px] 2xl:leading-[58px] lg:leading-[48px] leading-[27px] text-blueBayoux font-bold text-center`,
    ({ textAlign }) => textAlign === 'left' && tw`text-left`,
    ({ color }) => color === 'secondary2' && tw`text-secondary2`,
  ]}
`
