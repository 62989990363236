import Footer from '../Footer'
import Header from '../Header'
import * as S from './Layout.style'
import { ReactNode } from 'react'

interface LayoutProps {
  children: ReactNode
  noShadow?: boolean
}

const Layout = ({ children, noShadow }: LayoutProps) => (
  <S.Layout>
    <S.TopContent>
      <Header noShadow />
      {children}
    </S.TopContent>
    <Footer />
  </S.Layout>
)

export default Layout
