export const headerData = {
  navLinks: [
    {
      name: 'get-in-touch',
      title: 'Get in touch',
      url: '/#get-in-touch',
    },
    {
      name: 'login',
      title: 'Log in',
      url: '/login',
    },
  ],
}

export const footerData = {
  copyRight: 'Banto',
  linkList: [
    {
      name: 'terms',
      title: 'Terms of Use',
      url: '/terms-and-conditions',
    },
    {
      name: 'privacy',
      title: 'Privacy',
      url: '/privacy-policy',
    },
  ],
  socialLinks: [
    {
      icon: '/static/imgs/home/Facebook.svg',
      url: '/',
    },
    {
      icon: '/static/imgs/home/Instagram.svg',
      url: '/',
    },
    {
      icon: '/static/imgs/home/Twitter.svg',
      url: '/',
    },
  ],
}

export const quoteHomeData = {
  text: 'For Real Estate agencies who want a modern and flexible way to manage property.',
}

export const cloudToolsHomeData = {
  viewMore: {
    textMain:
      'Unleash the potential of API-powered data designed for real estate agencies',
    textMore:
      "Equip your trusted developer with our API suite to utilise valuable real estate data. Whether it's creating websites, extracting data insights, generating reports, or developing custom software applications, the possibilities are endless. Break free from limitations and unleash your creativity.",
  },
  imgSrc: '/static/imgs/home/cloud.png',
  imgBg: 'rgba(54, 190, 238, 0.10)',
  imgDes: 'Unleash the potential of API-powered data',
}

export const actionableHomeData = {
  imgSrc: '/static/imgs/home/clear-concise-actionable.png',
  title: 'Clear, concise and actionable data',
  description:
    'Opportunity is central to the Banto experience, with a suite of cloud-based tools that empower agencies with data freedom and building-block pricing, we feel that access to your own information is a right, not a privilege.',
}

export const pricingHomeData = {
  viewMore: {
    textMain:
      'Tailor your marketing toolkit: Pay for what you use above the base platform.',
    textMore:
      'Customise your Real Estate toolkit by selecting and paying only for the specific real estate marketing tools you need, while still enjoying the foundational insights and functionality provided in our base platform',
  },
  imgSrc: '/static/imgs/home/card-02.png',
  imgDes: 'The central nervous system your company needs',
}

export const administrationHomeData = {
  title: 'Tools to empower agencies',
  description:
    'Discover a suite of powerful real estate marketing tools just a click away, designed to seamlessly elevate your approach and tip the scales in your favour when wooing potential vendors.<br /><br />The Banto tools are your secret weapon for securing that coveted deal.',
  imgSrc: '/static/imgs/home/banto-agents-campain.png',
}

export const agencyLogosHomeData = {
  title: 'Join over 100 agencies using our software',
  imgSrc: [
    '/static/imgs/home/Agency01.jpg',
    '/static/imgs/home/Agency02.png',
    '/static/imgs/home/Agency03.png',
    '/static/imgs/home/Agency04.png',
    '/static/imgs/home/Agency06.png',
    '/static/imgs/home/Agency07.png',
    '/static/imgs/home/Agency08.gif',
    '/static/imgs/home/Agency09.png',
    '/static/imgs/home/Agency11.png',
  ],
}

export const testimonialsData = {
  title: 'Testimonials',
  content: [
    {
      contentTitle:
        '”Banto is our first stop for all our properties and people”',
      imgSrc: '/static/imgs/home/Testimonials_01.svg',
      name: 'Michelle - AZ Real Estate',
    },
    {
      contentTitle:
        '”Banto is our first stop for all our properties and people”',
      imgSrc: '/static/imgs/home/Testimonials_02.svg',
      name: 'Michelle - AZ Real Estate',
    },
    {
      contentTitle:
        '”Banto is our first stop for all our properties and people”',
      imgSrc: '/static/imgs/home/Testimonials_03.svg',
      name: 'Michelle - AZ Real Estate',
    },
  ],
}

export const illustrationHomeData = {
  title: 'Freedom. Connection. Flexibility.',
  description:
    'The real estate industry deserves a platform that puts people first.',
}

export const workTogetherHomeData = {
  title: 'Let’s work together',
  description:
    'Proudly 100% Australian-owned, we boast a seasoned team with decades of property data expertise. Fuelled by a collaborative mindset, we offer the data freedom, flexibility, and connectivity you desire.',
}

export const dashboardFeaturesData = {
  imgSrc: '/static/imgs/home/Marketing_Products.svg',
  title: 'Dashboard',
  description:
    'Opportunity is central to the Banto experience, with a suite of cloud-based tools that empower agencies with data freedom and building-block pricing, we feel that access to your own information is a right, not a privilege.',
}

export const heroFeaturesData = {
  title: 'Features',
  description:
    'A suite of cloud-based tools that empower agencies with data freedom.',
  bookButton: 'Book a demo',
  heroImg: '/static/imgs/home/Hero_Image.png',
}

export const trackPerformanceFeaturesData = {
  imgSrc: '/static/imgs/home/Track_Performance.svg',
  title: 'Track Performance',
  description:
    'Opportunity is central to the Banto experience, with a suite of cloud-based tools that empower agencies with data freedom and building-block pricing, we feel that access to your own information is a right, not a privilege.',
}

export const reportsFeaturesData = {
  imgSrc: '/static/imgs/home/Mockup.svg',
  title: 'Reports',
  description:
    'Opportunity is central to the Banto experience, with a suite of cloud-based tools that empower agencies with data freedom and building-block pricing, we feel that access to your own information is a right, not a privilege.',
  findOutMoreBtn: 'Find out more',
}

export const propertiesFeaturesData = {
  imgSrc: '/static/imgs/home/Property_Cards.png',
  title: 'Properties',
  description:
    'Opportunity is central to the Banto experience, with a suite of cloud-based tools that empower agencies with data freedom and building-block pricing, we feel that access to your own information is a right, not a privilege.',
}

export const marketingFeaturesData = {
  imgSrc: '/static/imgs/home/Marketing.jpg',
  title: 'Marketing',
  description:
    'Opportunity is central to the Banto experience, with a suite of cloud-based tools that empower agencies with data freedom and building-block pricing, we feel that access to your own information is a right, not a privilege.',
}
