import styled from 'styled-components'
import tw from 'twin.macro'

export const Quote = styled.div`
  ${tw`lg:pb-[50px] lg:pt-[80px] pt-[40px] pb-[25px]`}
`

export const QuoteInner = styled.div`
  ${tw`font-bold text-secondary2 lg:text-[32px] lg:leading-[41px] text-[22px] leading-[28px] max-w-[1024px] w-full mx-auto text-center relative`}
`

export const HighlightText = styled.span`
  ${tw`relative inline-block`}
`

export const UnderlineWrap = styled.figure`
  ${tw`absolute lg:w-[145px] w-[110px] lg:bottom-[-13px] bottom-[-8px] lg:left-[-5px] left-[-4px]`}
`
