import * as S from './Illustration.styled'
import * as G from '../../Global/Global.styled'
import { Col, Row } from '@Styles/grid.styled'
import SectionTitle from '@Website/Global/SectionTitle'

interface IllustrationCardProps {
  img: string
  title: string
  description: string
}

const IllustrationCard: React.FC<IllustrationCardProps> = ({
  img,
  title,
  description,
}) => {
  return (
    <S.IllustrationCard>
      <S.IllustrationCardImage src={img} alt={title} />
      <S.IllustrationCardTitle>{title}</S.IllustrationCardTitle>
      <S.IllustrationCardDes>{description}</S.IllustrationCardDes>
    </S.IllustrationCard>
  )
}

const Illustration = () => {
  return (
    <S.Illustration>
      <G.Container>
        <SectionTitle
          color='secondary2'
          title='Freedom. Connection. Flexibility.'
        />
        <S.Description>
          The real estate industry deserves a platform that puts people first.
        </S.Description>
        <S.IllustrationCardList>
          <Row gutter={22}>
            <Col md={4} flex>
              <IllustrationCard
                img='/static/imgs/home/freedom.png'
                title='Freedom'
                description='We believe agencies should have simple and easy access to the data they already own.'
              />
            </Col>
            <Col md={4} flex>
              <IllustrationCard
                img='/static/imgs/home/connection.png'
                title='Connection'
                description='Have the best of both worlds, with open API’s and integrations with your favourite tools.'
              />
            </Col>
            <Col md={4} flex>
              <IllustrationCard
                img='/static/imgs/home/flexibility.png'
                title='Flexibility'
                description='Our building block pricing model gives you all the tools you need, when you need them.'
              />
            </Col>
          </Row>
        </S.IllustrationCardList>
      </G.Container>
    </S.Illustration>
  )
}

export default Illustration
