import * as S from './FeatureBlock.styled'
import * as G from '../../Global/Global.styled'
import SectionTitle from '@Website/Global/SectionTitle'

interface FeatureBlockProps {
  content: {
    imgSrc: string
    title: string
    description: string
  }
  type: 'small' | 'big'
}

const FeatureBlock = ({
  content: { imgSrc, title, description },
  type,
}: FeatureBlockProps) => {
  return (
    <S.FeatureBlock type={type}>
      <G.Container>
        <S.FeatureBlockInner>
          <S.ImageWrap>
            <img src={imgSrc} alt='Banto' />
          </S.ImageWrap>
          <S.TextWrap>
            <SectionTitle
              title={title}
              className='feature-title'
              textAlign='left'
            />
            <S.Description dangerouslySetInnerHTML={{ __html: description }} />
          </S.TextWrap>
        </S.FeatureBlockInner>
      </G.Container>
    </S.FeatureBlock>
  )
}

export default FeatureBlock
