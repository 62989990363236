import * as S from './Hero.styled'
import * as G from '../../Global/Global.styled'
import Button from '@Global/Button'
import Link from 'next/link'
import { useEffect, useState } from 'react'

const Hero = () => {
  const [isClosedMacbook, setIsCloseMacbook] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsCloseMacbook(true)
      } else {
        setIsCloseMacbook(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <S.Hero>
      <G.Container>
        <S.HeroInner>
          <S.HeroInfo>
            <S.HeroTitle>Freedom, Connection & Flexibility</S.HeroTitle>
            <S.HeroImageMobile src='/static/imgs/home/macbook-mobile.png' />
            <S.HeroContent>
              The Real Estate software platform that empowers agencies
            </S.HeroContent>
            <Link href='#get-in-touch' passHref scroll={false}>
              <Button success size='md'>
                Get in touch
              </Button>
            </Link>
          </S.HeroInfo>
          <S.HeroImage isClosed={isClosedMacbook}>
            <S.HeroScreen>
              <S.HeroScreenClose />
              <S.HeroScreenOpen>
                <img
                  src='/static/imgs/home/banto-dashboard.jpg'
                  alt='Banto Dashboard'
                />
              </S.HeroScreenOpen>
            </S.HeroScreen>
            <S.HeroScreenBody />
          </S.HeroImage>
        </S.HeroInner>
      </G.Container>
      <S.HeroBg>
        <img src='/static/imgs/home/hero.png' alt='' />
      </S.HeroBg>
    </S.Hero>
  )
}

export default Hero
