import { Container } from '@Website/Global/Global.styled'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import * as S from './IntegrationProviders.styled'
import { Col, Row } from '@Styles/grid.styled'
import useWindowSize from '@Hooks/useWindowSize'
import SectionTitle from '@Website/Global/SectionTitle'

const providers = [
  [
    {
      logo: '/static/imgs/home/providers/renet.png',
      url: '',
    },
    {
      logo: '/static/imgs/home/providers/mri.png',
      url: '',
    },
    {
      logo: '/static/imgs/home/providers/rex.png',
      url: '',
    },
    
  ],
  [
    {
      logo: '/static/imgs/home/providers/our-property.png',
      url: '',
    },
    {
      logo: '/static/imgs/home/providers/locked-on.png',
      url: '',
    },
    {
      logo: '/static/imgs/home/providers/property-me.png',
      url: '',
    },
  ],
]

const providersMobile = [
  [
    {
      logo: '/static/imgs/home/providers/renet.png',
      url: '',
    },
    {
      logo: '/static/imgs/home/providers/mri.png',
      url: '',
    },
    {
      logo: '/static/imgs/home/providers/rex.png',
      url: '',
    },
    
  ],
  [
    {
      logo: '/static/imgs/home/providers/our-property.png',
      url: '',
    },
    {
      logo: '/static/imgs/home/providers/locked-on.png',
      url: '',
    },
    {
      logo: '/static/imgs/home/providers/property-me.png',
      url: '',
    },
  ],
]

const IntegrationProviders = () => {
  const windowsize = useWindowSize()
  const settings = {
    dots: true,
    arrows: true,
  }

  return (
    <S.IntegrationProviders>
      <Container>
        <SectionTitle title='Integration Providers' color='secondary2' />

        <S.SliderWrap>
          <Slider {...settings}>
            {windowsize.width > 767
              ? providers.map((it, idx) => (
                  <S.ProviderItems key={idx}>
                    <Row style={{ justifyContent: 'center' }}>
                      {it.map((provider, key) => (
                        <Col key={`provider-${key}`} sm={4} xs={4}>
                          <S.ProviderItem>
                            <S.ProviderItemImg src={provider.logo} alt='' />
                          </S.ProviderItem>
                        </Col>
                      ))}
                    </Row>
                  </S.ProviderItems>
                ))
              : providersMobile.map((it, idx) => (
                  <S.ProviderItems key={idx}>
                    <Row style={{ justifyContent: 'center' }}>
                      {it.map((provider, key) => (
                        <Col key={`provider-${key}`} sm={6} xs={6}>
                          <S.ProviderItem>
                            <S.ProviderItemImg src={provider.logo} alt='' />
                          </S.ProviderItem>
                        </Col>
                      ))}
                    </Row>
                  </S.ProviderItems>
                ))}
          </Slider>
        </S.SliderWrap>

        <S.IntegrationProvidersNote>
          Not on the list? Easy. We want to add additional providers so please
          get in touch.
        </S.IntegrationProvidersNote>
      </Container>
    </S.IntegrationProviders>
  )
}

export default IntegrationProviders
