import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const HoverBlockInner = styled.div`
  ${tw`flex lg:flex-row flex-col lg:mx-[-20px]`}
`

export const ImageWrap = styled.div<{ bgColor: string }>`
  ${tw`lg:flex-[0 0 calc(50% - 40px)] relative w-full rounded-[5px] lg:p-[78px] lg:mx-[20px] lg:p-[75px] p-[36px]`}

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};
    `}

  span {
    ${tw`block text-center 2xl:text-[28px] lg:text-[24px] text-[20px] font-bold 2xl:leading-[36px] lg:leading-[31px] leading-[26px] 2xl:mt-[57px] lg:mt-[50px] mt-[22px] 2xl:mb-[-18px]`}
  }
`

export const ViewMoreWrap = styled.div`
  ${tw`lg:flex-[0 0 calc(50% - 40px)] relative w-full lg:h-auto h-[350px] lg:mx-[20px] rounded-[5px] lg:mb-0 mb-[30px]`}
`

export const HoverBlock = styled.div<{ imgLeft?: boolean }>`
  ${tw`lg:py-[40px] py-[20px]`}

  ${HoverBlockInner} {
    ${({ imgLeft }) => imgLeft && tw`lg:flex-row-reverse flex-col-reverse`}
  }

  ${ViewMoreWrap} {
    ${({ imgLeft }) => imgLeft && tw`mb-0`}
  }

  ${ImageWrap} {
    ${({ imgLeft }) => imgLeft && tw`lg:mb-0 mb-[30px]`}
  }
`
