import * as S from './SectionTitle.styled'

interface SectionTitleProps {
  title: string
  className?: string
  textAlign?: 'center' | 'left'
  color?: 'grey1' | 'secondary2'
}
const SectionTitle = ({
  className,
  title,
  textAlign,
  color,
}: SectionTitleProps) => {
  return (
    <S.SectionTitle className={className} textAlign={textAlign} color={color}>
      {title}
    </S.SectionTitle>
  )
}
export default SectionTitle
