import Link from 'next/link'
import { useContext } from 'react'
import { useRouter } from 'next/router'
import { headerData } from '@Components/Website/Home/constants'
import { DEFAULT_USER_CONTEXT, UserContext } from '@Contexts/UserContext'
import { removeCookie } from '@Services/cookies'
import { Container } from '@Website/Global/Global.styled'
import Button from '@Global/Button'
import * as S from './Header.styled'

interface HeaderProps {
  noShadow?: boolean
}

const Header: React.FC<HeaderProps> = ({ noShadow }) => {
  const { navLinks } = headerData
  const router = useRouter()
  const { user, setUser } = useContext(UserContext)

  const logout = () => {
    removeCookie('token')
    setUser({ ...DEFAULT_USER_CONTEXT, isLogout: true })
    router.push('/login')
  }

  return (
    <S.Header $noShadow={noShadow}>
      <Container>
        <S.HeaderInner>
          <Link href='/' passHref>
            <S.LogoWrap>
              <img src='/static/imgs/home/Banto_Logo_Web.png' alt='Banto' />
            </S.LogoWrap>
          </Link>
          <S.Menu>
            {navLinks.map((it, id) =>
              it.name !== 'login' ? (
                it.url === '/login' ? (
                  user?.name ? (
                    <>
                      <Link key={id} href='/dashboard'>
                        <S.Link active={router.pathname === it.url}>
                          Dashboard
                        </S.Link>
                      </Link>
                      <S.Link
                        onClick={logout}
                        active={router.pathname === it.url}
                      >
                        Logout
                      </S.Link>
                    </>
                  ) : (
                    <Link key={id} href={it.url}>
                      <S.Link active={router.pathname === it.url}>
                        {it.title}
                      </S.Link>
                    </Link>
                  )
                ) : (
                  <Link key={id} href={it.url} passHref scroll={false}>
                    <S.Link active={router.pathname === it.url}>
                      {it.title}
                    </S.Link>
                  </Link>
                )
              ) : (
                <Link key={id} href={it.url} passHref>
                  <Button border size='md' color='primary' key={id}>
                    {it.title}
                  </Button>
                </Link>
              )
            )}
          </S.Menu>
        </S.HeaderInner>
      </Container>
    </S.Header>
  )
}

export default Header
