import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Hero = styled.div`
  ${tw`relative pt-[200px] pb-[120px] bg-[rgba(35, 112, 131, 0.16)]`}
`

export const HeroInner = styled.div`
  ${tw`flex items-center lg:flex-row flex-col lg:text-left text-center`}
`

export const HeroBg = styled.div`
  ${tw`absolute right-0 bottom-0 w-[40%] z-[-1]`}

  img {
    ${tw`md:block hidden`}

    &.mobile {
      ${tw`md:hidden block h-[80%]`}
    }
  }
`

export const HeroInfo = styled.div`
  ${tw`relative z-10 lg:w-[35%] lg:flex-[0 0 35%] w-full`}

  .page-title {
    ${tw`lg:mb-[73px] md:mb-[50px] mb-[30px]`}
  }

  button {
    ${tw`lg:px-[40px] lg:py-[15px] font-bold lg:text-[24px] text-base lg:w-auto w-[240px]`}
  }
`

export const HeroTitle = styled.h1`
  ${tw`font-bold lg:text-[48px] text-[32px] mb-[40px] text-primary1 lg:leading-[110%]`}
`

export const HeroContent = styled.p`
  ${tw`lg:text-[32px] text-base text-blueBayoux font-medium leading-[110%] lg:mb-[40px] mb-[32px] lg:max-w-full max-w-[240px] mx-auto`}
`

export const HeroScreen = styled.div`
  height: 578px;
  position: relative;
  transform-style: preserve-3d;
  -webkit-perspective: 2500;
  -webkit-perspective-origin: center bottom;

  img {
    position: absolute;
    width: 94%;
    left: 3%;
    height: 88%;
    top: 6%;
    background: #151515;
    transition: opacity 0.05s linear 0.3s;
    opacity: 1;
  }
`

export const HeroScreenClose = styled.div`
  position: absolute;
  transform: rotateX(90deg) scale(0.9);
  transform-origin: center bottom -580px;
  transition: all 1s ease-in-out, bottom 0.1s ease-in-out;
  bottom: -5px;
  width: 860px;
  height: 13px;
  background: -webkit-linear-gradient(
    top,
    #acadb3 0%,
    #acadb3 2%,
    #91939b 2%,
    #91939b 4%,
    #a7a9b0 4%,
    #f5f6f7 67%,
    #f5f6f7 100%
  );
  border-radius: 150px/9px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 2px;
  box-shadow: inset 10px 0 10px -12px #000, inset 60px 0 70px -60px #fff,
    inset 60px 0 70px -60px #fff, inset 100px 0 150px -100px #000,
    inset -10px 0 10px -12px #000, inset -60px 0 70px -60px #fff,
    inset -60px 0 70px -60px #fff, inset -100px 0 150px -100px #000,
    0 4px 0 -3px #727379, 0 6px 0 -4px #1a1a1c;
`

export const HeroScreenOpen = styled.div`
  transform: translateZ(-580px) rotateX(0deg);
  transform-origin: center bottom;
  transition: all 1s ease-in-out;
  height: 578px;
  width: 856px;
  position: relative;
  border-radius: 26px 26px 10px 10px;
  background-color: #000;
  border: 2px solid #c8c9cc;
  border-bottom: none;
  box-shadow: inset 0 -11px 0 0 #16191d, inset 0 -12px 0 0 #111418,
    inset 0 -13px 0 0 #262a2f, inset 0 -14px 0 0 #16191d,
    inset 0 0 0 1px #1b1e21, inset 0 0 0 2px #3d4248, inset 0 0 0 3px #181b1f;

  &::before {
    content: '';
    width: 9px;
    height: 9px;
    border-radius: 4px;
    left: 50%;
    margin-left: -4px;
    top: 13px;
    box-shadow: inset 1px 1px 0 0 #0d1115, inset 2px 2px 0 0 #090c0f,
      inset 3px 3px 0 0 #0c0e12, inset 4px 4px 0 0 #0b1628,
      inset -1px -1px 0 0 #202428, inset -2px -2px 0 0 #1c1f23,
      inset -3px -3px 0 0 #15171a, inset -4px -4px 0 0 #171d25;
    background: #041423;
    position: absolute;
    z-index: 1000;
  }
`

export const HeroScreenBody = styled.div`
  position: relative;
  width: 100%;
  height: 27px;
  border-radius: 30px/10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background: -webkit-linear-gradient(
    top,
    #ffffff 0%,
    #fefdff 2%,
    #dfdee3 2%,
    #dfdee3 58%,
    #adadb5 58%,
    #adadb5 60%,
    #8e8e96 60%,
    #8e8e96 65%,
    #96969e 65%,
    #62626a 84%,
    #28282a 98%,
    #28282a 100%
  );
  box-shadow: inset 10px 0 10px -12px #000, inset 60px 0 70px -60px #fff,
    inset 60px 0 70px -60px #fff, inset 100px 0 150px -100px #000,
    inset -10px 0 10px -12px #000, inset -60px 0 70px -60px #fff,
    inset -60px 0 70px -60px #fff, inset -100px 0 150px -100px #000;

  &::before {
    content: '';
    width: 120px;
    margin-left: -60px;
    height: 10px;
    position: absolute;
    background: -webkit-linear-gradient(
      top,
      #afafb7 0%,
      #c9cacf 31%,
      #ccccce 100%
    );
    top: 0;
    left: 50%;
    border-radius: 0 0 10px 10px;
    box-shadow: inset 10px 0 10px -10px #000, inset -10px 0 10px -10px #000,
      inset 0 -10px 2px -10px rgba(0, 0, 0, 0.2);
  }
`

export const HeroImageMobile = styled.img`
  ${tw`w-full mb-[30px] lg:hidden max-w-[320px] mb-[30px] mx-auto`}
`

export const HeroImage = styled.div<{ isClosed?: boolean }>`
  width: 860px;
  margin: -100px auto 20px;
  ${tw`lg:block hidden`}

  ${({ isClosed }) =>
    isClosed &&
    css`
      ${HeroScreenClose} {
        transform: rotateX(0deg) scale(1);
        bottom: 0;
        transition: all 1s ease-in-out, bottom 0.1s ease-in-out 0.9s;
      }

      ${HeroScreenOpen} {
        transform: translateZ(-580px) rotateX(-90deg);
      }

      img {
        opacity: 0.2;
        transition: opacity 0.05s linear 0.7s;
      }
    `}
`
