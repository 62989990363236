import * as S from './HoverBlock.styled'
import * as G from '../../Global/Global.styled'
import ViewMore from './ViewMore'

interface HoverBlockProps {
  content: {
    viewMore: {
      textMain: string
      textMore: string
    }
    imgBg?: string
    imgSrc: string
    imgDes?: string
  }
  imgLeft?: boolean
  textBg?: 'primary1' | 'secondary2'
}

const HoverBlock = ({ content, imgLeft, textBg }: HoverBlockProps) => {
  const {
    viewMore: { textMain, textMore },
    imgSrc,
    imgBg = 'rgba(35, 112, 131, 0.10)',
    imgDes,
  } = content

  return (
    <S.HoverBlock imgLeft={imgLeft}>
      <G.Container>
        <S.HoverBlockInner>
          <S.ViewMoreWrap>
            <ViewMore
              textMain={textMain}
              textMore={textMore}
              bgColor={textBg}
            />
          </S.ViewMoreWrap>
          <S.ImageWrap bgColor={imgBg}>
            <img src={imgSrc} alt='Banto' />
            {imgDes && <span>{imgDes}</span>}
          </S.ImageWrap>
        </S.HoverBlockInner>
      </G.Container>
    </S.HoverBlock>
  )
}

export default HoverBlock
