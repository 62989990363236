import FeatureBlock from '@Website/Home/FeatureBlock'
import Illustration from '@Website/Home/Illustration'
import Hero from '@Website/Home/Hero'
import HoverBlock from '@Website/Home/HoverBlock'
import Quote from '@Website/Home/Quote'
import Head from 'next/head'
import {
  actionableHomeData,
  administrationHomeData,
  cloudToolsHomeData,
  pricingHomeData,
} from '@Website/Home/constants'
import GlobalStyles from '@Website/Global/GlobalStyles'
import GetInTouch from '@Website/Home/GetInTouch'
import IntegrationProviders from '@Website/Home/IntegrationProviders'
import Layout from '@Website/Global/Layout'
import WorkTogether from '@Website/Global/WorkTogether'

interface HomePageProps {}

const HomePage: React.FC<HomePageProps> = () => {
  return (
    <>
      <GlobalStyles />
      <Head>
        <title>Banto</title>
      </Head>
      <Layout>
        <Hero />
        <Quote />
        <HoverBlock content={cloudToolsHomeData} />
        <FeatureBlock content={actionableHomeData} type='big' />
        <HoverBlock content={pricingHomeData} imgLeft textBg='secondary2' />
        <FeatureBlock content={administrationHomeData} type='small' />
        <IntegrationProviders />
        <Illustration />
        <WorkTogether />
        <GetInTouch />
      </Layout>
    </>
  )
}

HomePage.defaultProps = {}

export default HomePage
