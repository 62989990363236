import styled from 'styled-components'
import tw from 'twin.macro'

export const WorkTogetherInner = styled.div`
  ${tw`max-w-[960px] w-full mx-auto flex flex-col items-center`}
`

export const Description = styled.p`
  ${tw`text-blueBayoux font-bold lg:text-[20px] lg:leading-[32px] text-[16px] leading-[25px] text-center md:mt-[40px] mt-[20px]`}
`

export const WorkTogether = styled.div`
  ${tw`py-[40px] lg:py-[80px]`}
`
