import * as S from './Footer.styled'
import * as G from '../Global.styled'
import Link from 'next/link'
import { footerData } from '@Components/Website/Home/constants'

const Footer = () => {
  const { copyRight, linkList, socialLinks } = footerData
  return (
    <S.Footer>
      <G.Container>
        <S.FooterInner>
          <S.LeftContent>
            <Link href='/'>
              <S.LogoWrap>
                <img src='/static/imgs/home/logo.png' alt='Banto' />
              </S.LogoWrap>
            </Link>
            <S.LinkList>
              <S.CopyRight>
                <S.CopyRightIcon>© </S.CopyRightIcon>
                {copyRight}
              </S.CopyRight>
              {linkList.map((it, id) => (
                <Link key={id} href={it.url} passHref>
                  <S.Link>{it.title}</S.Link>
                </Link>
              ))}
            </S.LinkList>
          </S.LeftContent>
          <S.RightContent>
            {socialLinks.map((it, id) => (
              <Link key={id} href={it.url} passHref>
                <S.SocialLink>
                  <img src={it.icon} alt='Banto' />
                </S.SocialLink>
              </Link>
            ))}
          </S.RightContent>
        </S.FooterInner>
      </G.Container>
    </S.Footer>
  )
}
export default Footer
