import styled from 'styled-components'
import tw from 'twin.macro'

export const Footer = styled.div`
  ${tw`pt-[55px] pb-[55px] bg-secondary2 font-heading`}
`

export const FooterInner = styled.div`
  ${tw`flex justify-between md:flex-row flex-col`}
`

export const LinkList = styled.div`
  ${tw`flex flex-wrap justify-start items-center`}
`

export const LogoWrap = styled.div`
  ${tw`relative w-[116px] cursor-pointer flex-shrink-0 sm:mb-0 mb-5 mr-[33px]`}
`

export const CopyRight = styled.p`
  ${tw`text-white font-bold text-sm mr-[40px] whitespace-nowrap`}
`

export const CopyRightIcon = styled.span`
  ${tw`font-normal`}
`

export const LeftContent = styled.div`
  ${tw`flex md:justify-start justify-between sm:flex-row flex-col w-full md:mb-0 mb-5 sm:items-center items-start`}
`

export const RightContent = styled.div`
  ${tw`flex items-center`}
`

export const Link = styled.span`
  ${tw`text-white font-bold text-sm hover:underline cursor-pointer mr-[40px]`}
  text-decoration-thickness: 0px;
`

export const SocialLink = styled.span`
  ${tw`relative w-6 h-6 flex items-center justify-center cursor-pointer ml-5`}
`
