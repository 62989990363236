import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const FeatureBlockInner = styled.div`
  ${tw`bg-greyLighter rounded-[5px] flex lg:flex-row flex-col items-center justify-between`}
`

export const ImageWrap = styled.figure`
  ${tw`relative lg:max-w-[56.2%] max-w-none w-full xl:ml-[-20px] ml-0 flex-shrink-0`}
`

export const TextWrap = styled.div`
  ${tw`lg:max-w-[416px] max-w-none w-full xl:mr-[90px] lg:mr-[50px] mr-0 lg:px-0 px-[30px] lg:pb-0 pb-[20px] flex-shrink`}
  ${tw`mobile:(pb-[30px])`}
  .feature-title {
    ${tw`md:mb-[25px] mb-[32px] text-secondary2`}
  }
`

export const Description = styled.p`
  ${tw`text-blueBayoux lg:text-[20px] text-[16px] lg:leading-[36px] leading-[28px] font-bold`}
`

export const FeatureBlock = styled.div<{ type: string }>`
  ${tw`py-[40px]`}

  ${FeatureBlockInner} {
    ${({ type }) => type === 'small' && tw`lg:flex-row-reverse flex-col`}
  }

  ${TextWrap} {
    ${Description} {
      ${({ type }) => type === 'small' && tw`text-grey1`}
    }
  }

  ${({ type }) =>
    type === 'small' &&
    css`
      ${TextWrap} {
        ${tw`pl-[100px] lg:max-w-[47%] mr-0 lg:pl-[40px] pl-[30px]`}
      }

      ${ImageWrap} {
        ${tw`lg:max-w-[45%] lg:mr-12 lg:mt-10`}
      }
    `}
`
