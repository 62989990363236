import { FieldErrors, FieldValues, UseFormRegister } from 'react-hook-form'

export const getErrorMsg = (errors: any) => {
  let msg: any = []
  Object.keys(errors).map((key) => {
    errors[key].map((it: string) => {
      msg.push(it)
    })
  })

  return msg.join('<br />')
}

export function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (
    fieldName: keyof T,
    error: { type: string; message: string }
  ) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: 'server',
      message: errors[key as keyof T]!.join('. '),
    })
  })
}

export const getInputProps = (
  name: string,
  register: UseFormRegister<FieldValues>,
  errors: FieldErrors,
  submitCount?: number
) => {
  return {
    name,
    error: errors && errors[name]?.message,
    register: register ? register(name) : undefined,
  }
}

export const getSelectedLabel = (
  data: { label: string; value: string }[],
  value: string
) => {
  if (!value || !data || !data.length) return ''
  const selected = data.find((it) => it.value === value)
  if (selected.label) return selected.label
  return ''
}
