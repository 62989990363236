import styled from 'styled-components'
import tw from 'twin.macro'

export const Description = styled.p`
  ${tw`text-secondary2 md:text-xl text-base md:leading-[28px] leading-[28px] text-center font-bold md:mt-5 mt-[15px]`}
`

export const Illustration = styled.div`
  ${tw`bg-[rgba(35, 112, 131, 0.10)] lg:pt-[80px] pt-[40px] lg:pb-[62px] pb-[32px] mt-[40px]`}
`

export const IllustrationCardList = styled.div`
  ${tw`pt-[70px]`}
  ${tw`mobile:(pt-[40px])`}
`

export const IllustrationCard = styled.div`
  ${tw`rounded-[5px] bg-white shadow-[0px 9.333px 18.667px 0px rgba(72, 89, 111, 0.14)] p-[46px] mb-[18px] text-center`}
  ${tw`mobile:(p-[34px])`}
`

export const IllustrationCardImage = styled.img`
  ${tw`mb-[37px] h-[175px] mx-auto`}
  ${tw`mobile:(h-[128px] mb-[28px])`}
`

export const IllustrationCardTitle = styled.h3`
  ${tw`mb-[10px] font-bold 2xl:text-[28px] xl:text-[24px] text-[20px] 2xl:leading-[46px] xl:leading-[40px] leading-[34px] text-grey1`}
  ${tw`mobile:(mb-[8px])`}
`

export const IllustrationCardDes = styled.div`
  ${tw`2xl:text-[20px] xl:text-[18px] text-[15px] 2xl:leading-[32px] xl:leading-[28px] leading-[23px] text-blueBayoux`}
`
