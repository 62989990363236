import { Container } from '@Website/Global/Global.styled'
import * as Yup from 'yup'
import Input from '@Global/FormInput/Input'
import Textarea from '@Global/FormInput/Textarea'
import { FormGroup, Label } from '@Styles/form.styled'
import { Col, Row } from '@Styles/grid.styled'
import Button from '@Global/Button'
import { useForm } from 'react-hook-form'
import useYupValidationResolver from '@Hooks/useYupValidationResolver'
import { useMemo, useState } from 'react'
import { ERROR_MESSAGE } from '@Constants/message'
import { getInputProps } from '@Utils/form'
import ErrorMsg from '@Global/FormInput/ErrorMsg'
import SectionTitle from '@Website/Global/SectionTitle'
import { WebsiteApi } from '@Services/api/website'
import { WebsiteContact } from '@Services/types'
import Typography from '@Global/Typography'
import * as S from './GetInTouch.styled'

const GET_IN_TOUCH_FORM = {
  initialValues: {
    name: '',
    phone_number: '',
    email_address: '',
    message: '',
  },
  schema: Yup.object().shape({
    name: Yup.string().required(ERROR_MESSAGE.required),
    phone_number: Yup.string().required(ERROR_MESSAGE.required),
    email_address: Yup.string()
      .email(ERROR_MESSAGE.email)
      .required(ERROR_MESSAGE.required),
    message: Yup.string().required(ERROR_MESSAGE.required),
  }),
}

const GetInTouch = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const validationSchema = useMemo(() => GET_IN_TOUCH_FORM.schema, [])
  const resolver = useYupValidationResolver(validationSchema)

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver,
    defaultValues: { ...GET_IN_TOUCH_FORM.initialValues },
  })

  const onSubmit = async (values: WebsiteContact) => {
    await WebsiteApi.submitContact(values)
    setIsSuccess(true)
  }

  return (
    <S.GetInTouch id='get-in-touch'>
      <Container>
        <S.FormWrap>
          <S.FormInfo>
            <img src='/static/imgs/home/contact.png' alt='' />
            <SectionTitle
              title='Get in touch'
              textAlign='left'
              color='secondary2'
            />
            <p>
              Grow your real estate agency with data freedom, flexibility, and
              the connectivity you desire
            </p>
          </S.FormInfo>
          <S.Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <SectionTitle
              title='Get in touch'
              textAlign='left'
              color='secondary2'
            />
            {isSuccess ? (
              <Typography size='lg'>
                Thank you for contacting us! We will get back to you shortly.
              </Typography>
            ) : (
              <>
                <FormGroup>
                  <Label>Name</Label>
                  <Input
                    required
                    type='text'
                    placeholder='Enter your name'
                    {...getInputProps('name', register, errors)}
                  />
                  <ErrorMsg message={errors.name?.message} />
                </FormGroup>
                <Row gutter={10}>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Phone Number</Label>
                      <Input
                        required
                        type='text'
                        placeholder='Enter your phone number'
                        {...getInputProps('phone_number', register, errors)}
                      />
                      <ErrorMsg message={errors.phone_number?.message} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Email Address</Label>
                      <Input
                        required
                        type='email'
                        placeholder='Enter your email address'
                        {...getInputProps('email_address', register, errors)}
                      />
                      <ErrorMsg message={errors.email_address?.message} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label>Message</Label>
                  <Textarea
                    required
                    placeholder='Enter your message...'
                    {...getInputProps('message', register, errors)}
                  />
                  <ErrorMsg message={errors.message?.message} />
                </FormGroup>
                <S.FormAction>
                  <Button
                    loading={isSubmitting}
                    disabled={isSubmitting}
                    type='submit'
                    success
                    size='md'
                  >
                    Send
                  </Button>
                </S.FormAction>
              </>
            )}
          </S.Form>
        </S.FormWrap>
      </Container>
    </S.GetInTouch>
  )
}

export default GetInTouch
