import { InputHTMLAttributes, useState } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'
import * as S from './Textarea.styled'

interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean
  success?: boolean
  name: string
  register?: UseFormRegisterReturn
  withCounter?: boolean
}

const Textarea: React.FC<InputProps> = (props) => {
  const [textLength, setTextLength] = useState<number>(0)
  const { name, register, withCounter } = props

  const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    withCounter && setTextLength(e.target.value.length)
    props.onChange && props.onChange(e)
  }

  const inputProps = {
    ...props,
    className: name,
    onChange: onChange,
  }
  return (
    <S.TextareaWrap>
      {register ? (
        <S.Textarea {...inputProps} {...register} />
      ) : (
        <S.Textarea {...inputProps} />
      )}
      {withCounter && (
        <S.Count>
          ({textLength}/{props.maxLength})
        </S.Count>
      )}
    </S.TextareaWrap>
  )
}

Textarea.defaultProps = {}

export default Textarea
