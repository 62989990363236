import styled, { css } from 'styled-components'
import tw from 'twin.macro'

export const Header = styled.div<{ $noShadow: boolean }>`
  ${tw`pt-[10px] pb-[12px] fixed w-full z-[9999] font-heading shadow-[0px 4px 20px 0px rgba(0, 0, 0, 0.1)] bg-[rgba(220, 232, 235, 1)]`}

  ${({ $noShadow }) =>
    $noShadow &&
    css`
      box-shadow: none;
    `}
`

export const HeaderInner = styled.div`
  ${tw`flex justify-between items-center`}
`

export const LogoWrap = styled.div`
  ${tw`max-w-[116px] w-full cursor-pointer flex py-[12px]`}
`

export const Menu = styled.div`
  ${[tw`flex items-center duration-300 z-10`]}

  button {
    ${tw`bg-transparent text-secondary2 px-[20px] py-[7px] md:text-base text-[13px] font-bold border-[rgba(35, 112, 131, 0.40)] hover:(bg-primary)`}
  }
`

export const Link = styled.span<{ active: boolean }>`
  ${tw`md:mr-10 mr-[16px] mb-0 text-secondary2 md:text-base text-[13px] font-bold cursor-pointer font-heading hover:underline`}

  ${[({ active }) => active && tw`font-extrabold`]}
`
